import { toArray } from '~/modules/core/helpers/core/toArray'
import { ADCarousel } from './objects/ADCarousel'

/**
 * Helper to initialise a carousel
 * This must be fired in a component mounted hook
 * Use ref attributes within the vue component and then pass in
 * this.$refs.element to scope the instance to that component
 *
 *  @param Object options {
 *    carouselElement: HTMLElement
 *    paginationContainer: HTMLElement
 *    perPage: number or object containing breakpoints and numbers
 *    duration: number
 *    onInit: Function
 *    onChange: Function
 * }
 *  @return void
 */
export const initCarousel = (options) => {
  let {
    carouselElement = null,
    paginationContainer = null,
    perPage = 1,
    duration = 500,
    onInit = function() {
      this._addSlideIds()
      // this.innerElements[0].parentNode.setAttribute('data-slide-state', 'active')
    },
    onChange = function() {
      this._updatePagination()
      // this._disablePaginationArrows()
      // this._assignSlideStates.call(this)
    }
  } = options

  if (carouselElement) {
    // create the carousel instance
    const carousel = new ADCarousel({
      selector: carouselElement,
      perPage: perPage,
      duration: duration,
      onInit: onInit,
      onChange: onChange,
      loop: false
    })

    if (paginationContainer) {
      // Add Pagination
      carousel._createPagination(paginationContainer)

      carousel._attachArrows(paginationContainer)
    }

    // Define breakpoints for responsive attach / dettach.
    carousel._setAttachment([
      {
        breakPoint: 0,
        type: { attach: false }
      },
      {
        breakPoint: 768,
        type: { dettach: false }
      }
    ])

    return carousel
  } else {
    return false
  }
}
