/* eslint-disable */
/* Dependencies */
import Siema from 'siema'
import { createPaginationItem, createArrow } from '../helpers/carousel'
import { arrayOfNumbers } from '~/modules/core/helpers/core/arrayOfNumbers'

/**
 * Carosuel Instance that extends the base Siema Module.
 * @class
 */
export class ADCarousel extends Siema {
  _currrentViewWidth = window.innerWidth

  constructor(options) {
    super(options)
  }

  resizeHandler() {
    // This is used to prevent the resize handler firing when the
    // Android keyboard pops up.
    if (Math.abs(window.innerWidth - this._currrentViewWidth) === 0) {
      return
    }

    this._currrentViewWidth = window.innerWidth

    // update perPage number dependable of user value
    this.resolveSlidesNumber()

    // relcalculate currentSlide
    // prevent hiding items when browser width increases
    if (this.currentSlide + this.perPage > this.innerElements.length) {
      this.currentSlide =
        this.innerElements.length <= this.perPage
          ? 0
          : this.innerElements.length - this.perPage
    }

    this.selectorWidth = this.selector.offsetWidth

    this.buildSliderFrame()
  }

  /**
   * Used to add slide identifiers, Particuarly useful to allow &:last-child selection in css.
   * @memberof ADCarousel
   * @example
   * // Create carosuel
   * const carousel = new ADCarosuel({
   *     ...,
   *     onInit: function() {
   *         // Add slide id's
   *         this._addSlideIds.call(this);
   *     }
   * });
   */
  _addSlideIds() {
    const siema = this
    // Loop through the slides
    siema.innerElements.forEach((currentSlide) => {
      // Get the parent
      const slide = currentSlide.parentElement
      // Add slide identifier
      slide.setAttribute('data-slide', '')
    })
  }

  /**
   * Used to create pagination.
   * @memberof ADCarousel
   * @param {HTMLELement} container - The element for the pagination to be appended to.
   * @example
   * // Get the pagination container
   * const paginationContainer = document.querySelector("[data-pagination]");
   *
   * // Create carosuel
   * const carousel = new ADCarosuel({
   *     .....
   * });
   *
   * // Create the pagination
   * createPagination(this, paginationContainer);
   */
  _createPagination(container) {
    const siema = this
    if (!siema._paginationContainer) {
      const paginationContainer = document.createElement('div')
      paginationContainer.setAttribute('data-pagination-container', '')
      paginationContainer.addClass('pagination__button-wrapper')

      container.appendChild(paginationContainer)
      siema._paginationContainer = paginationContainer
    }

    // Define the pagination
    siema._definePagination().then((createdItems) => {
      siema._paginationContainer.innerHTML = ''
      // Loop throught the items
      createdItems.items.forEach((currentItem) => {
        // Add the items to the container.
        siema._paginationContainer.appendChild(currentItem)
      })
    })
  }

  /**
   * Used to define the pagination instance & get pagination items
   * @memberof ADCarousel
   * @returns {Promise} - returns the defined items created for the pagination.
   * @example
   * // Define the pagination
   * siema._definePagination()
   * .then((createdItems) => {
   * siema._paginationContainer.innerHTML = "";
   *    // Loop throught the items
   *    createdItems.items.forEach((currentItem) => {
   *        // Add the items to the container.
   *        container.appendChild(currentItem);
   *     });
   * });
   */
  _definePagination() {
    const siema = this

    // Return a promise
    return new Promise((resolve) => {
      // Create pagination object on instance
      siema._pagination = {
        items: []
      }

      // Loop through the slides
      siema._pagination.items = siema.innerElements.map(
        (currentSlide, index) => {
          // If the slide is valid in relevance to the per page count
          if (
            index / siema.perPage === parseInt(index / siema.perPage) ||
            index === 0
          ) {
            return createPaginationItem(index, siema)
          }
        }
      )

      // Remove undefinds
      siema._pagination.items = siema._pagination.items.filter(
        (item) => item !== undefined
      )

      // Set Active Item
      let activeItem =
        siema.perPage > 1
          ? Math.ceil(siema.currentSlide / siema.perPage)
          : siema.currentSlide

      if (siema._pagination.items[activeItem]) {
        // Set the active element
        siema._pagination.items[activeItem].setAttribute('data-active', 'true')
      }
      // Resolve
      resolve(siema._pagination)
    })
  }

  /**
   * Used to allow for the re-binding of the carousel pagination. (use this if perPage changes).
   * @memberof ADCarosuel
   * @param {Array} settings - Array of breakpoint items.
   * @example
   *  // Add Pagination
   *  carousel._createPagination(paginationContainer);
   *
   * // Add points to re-draw pagination
   * carousel._setResponsivePagination([
   *     {
   *         breakPoint: 1000
   *     }
   * ]);
   */
  _setResponsivePagination(settings) {
    const siema = this

    // Set responsive object
    siema._responsivePagination = {
      settings: settings
        .sort((item1, item2) => item1.breakPoint - item2.breakPoint)
        .reverse(),
      handleResize: siema._handleResponsivePagination.bind(this),
      resizeTimeout: undefined,
      activeBreakPoint: undefined
    }

    // Bind resize event
    window.addEventListener(
      'resize',
      siema._responsivePagination.handleResize,
      true
    )
  }

  /**
   * Used to handle window resize in releation re-binding the pagination.
   * @example
   * // Should not be called directly. Is bound to window resize event.
   */
  _handleResponsivePagination() {
    const siema = this

    // Clear previous timeout
    clearInterval(siema._responsivePagination.resizeHandler)

    // Debounce
    siema._responsivePagination.resizeHandler = setTimeout(() => {
      // Grab the settings
      const settings = siema._responsivePagination.settings

      // set counter
      let counter = 0

      // Loop through breakpoints
      while (counter < settings.length) {
        // If the desired break point is already set
        if (
          window.innerWidth >= settings[counter].breakPoint &&
          settings[counter] === siema._responsivePagination.activeBreakPoint
        ) {
          break
        } else if (window.innerWidth >= settings[counter].breakPoint) {
          // Set a new active breakpoint
          siema._responsivePagination.activeBreakPoint = settings[counter]
          // Re bind the pagination
          siema._createPagination(siema._paginationContainer)
          break
        }

        counter++
      }
    }, 50)
  }

  /**
   * Used to update the pagination active item.
   * @memberof ADCarousel
   * @example
   * // Create carosuel
   * const carousel = new ADCarosuel({
   *     ....
   *     onChange: function() {
   *         this._updatePagination.call(this);
   *     }
   * });
   */
  _updatePagination() {
    const siema = this

    const activeItem = siema._pagination.items.filter(
      (item) => item.getAttribute('data-active') !== null
    )

    if (activeItem.length > 0) {
      activeItem[0].removeAttribute('data-active')
    }

    // If the carousel is more than the max (returns -1)
    if (siema.currentSlide < 0) {
      // Set the last item as active if loop is set or return to first
      !siema.loop
        ? siema._pagination.items[0].setAttribute('data-active', 'true')
        : siema._pagination.items[
            siema._pagination.items.length - 1
          ].setAttribute('data-active', 'true')
    } else {
      // Get desired dot
      let desiredIndex =
        siema.perPage === 1
          ? siema.currentSlide
          : Math.ceil(siema.currentSlide / siema.perPage)

      // Set the active item
      siema._pagination.items[desiredIndex].setAttribute('data-active', 'true')
    }
  }

  /**
   * Used to set attach / detach events based on the viewport size.
   * @memberof ADCarousel
   * @param {Array} settings - Array of breakpoint objects.
   * @example
   * // Create carosuel
   * const carousel = new ADCarosuel({
   *     .....
   * });
   *
   * // Set attachment
   * carousel._setAttachment.call(carousel, [
   *     {
   *         breakPoint: 0,
   *         type: { attach: true }
   *     },
   *     {
   *         breakPoint: 600,
   *         type: { dettach: true }
   *     }
   *  ]);
   */
  _setAttachment(settings) {
    const siema = this

    // Set attachment object
    siema._attachment = {
      settings: settings
        .sort((item1, item2) => item1.breakPoint - item2.breakPoint)
        .reverse(),
      handleResize: siema._handleResponsiveAttachment.bind(this),
      resizeTimeout: undefined,
      activeBreakPoint: undefined
    }

    window.addEventListener('resize', siema._attachment.handleResize, true)

    siema._attachment.handleResize({ bypass: true })
  }

  /**
   * Used to handle window resize in releation to attachment.
   * @memberof ADCarousel
   * @example
   * // Should not be called directly. Is bound to window resize event.
   */
  _handleResponsiveAttachment({ bypass }) {
    const siema = this

    // Fix for IE11 Being Slow With Siema Document Fragment, This fixes issue but causes a break flash on tablet. Graceful fallback.
    let isIE11 =
      (!!window.MSInputMethodContext && !!document.documentMode) ||
      window.navigator.userAgent.indexOf('Edge') > -1

    const applySettings = () => {
      // Grab the settings
      const settings = siema._attachment.settings

      // set counter
      let counter = 0

      // Loop through breakpoints
      while (counter < settings.length) {
        // If the desired break point is already set
        if (
          window.innerWidth >= settings[counter].breakPoint &&
          settings[counter] === siema._attachment.activeBreakPoint
        ) {
          break
        } else if (window.innerWidth >= settings[counter].breakPoint) {
          // Set a new active breakpoint
          siema._attachment.activeBreakPoint = settings[counter]

          // Attach
          if (settings[counter].type.attach) {
            // Initiate carousel
            siema.init()
            // Trigger the resize handler
            siema.resizeHandler()
            // Reset to slide 0
            siema.goTo(0)
            break
          }
          // Dettach
          else if (settings[counter].type.dettach) {
            // Destroy the carousel and reset markup
            siema.destroy(true)
            break
          }
        }

        counter++
      }
    }

    if (bypass || !isIE11) {
      applySettings()
      return
    }

    clearTimeout(siema._attachment.responsiveTimeout)
    siema._attachment.responsiveTimeout = setTimeout(() => {
      applySettings()
    })
  }

  _attachArrows(container) {
    const icon = `
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
        <defs>
          <path id="ic_chevron_right_24px-a" d="M0.183058262,1.06694174 C-0.0610194206,0.822864056 -0.0610194206,0.427135944 0.183058262,0.183058262 C0.427135944,-0.0610194206 0.822864056,-0.0610194206 1.06694174,0.183058262 L9.99996024,9.11607699 C10.2346093,9.35039212 10.3664619,9.66839214 10.3664619,10 C10.3664619,10.3316079 10.2346093,10.6496079 10.0002751,10.8836084 L1.06694174,19.8169417 C0.822864056,20.0610194 0.427135944,20.0610194 0.183058262,19.8169417 C-0.0610194206,19.5728641 -0.0610194206,19.1771359 0.183058262,18.9330583 L9.11611664,10 L0.183058262,1.06694174 Z"/>
        </defs>
        <g fill="none" fill-rule="evenodd">
          <polygon points="0 0 24 0 24 24 0 24"/>
          <use fill="currentColor" fill-rule="nonzero" transform="translate(7 2)" xlink:href="#ic_chevron_right_24px-a"/>
        </g>
      </svg>

        `

    const arrows = {
      next: createArrow({ ariaText: 'Next Slide', icon, direction: 'next' }),
      prev: createArrow({ ariaText: 'Previous Slide', icon, direction: 'prev' })
    }

    container.insertAdjacentElement('afterbegin', arrows.prev)
    container.insertAdjacentElement('beforeend', arrows.next)

    this._attachArrowEvents(arrows)
  }

  _attachArrowEvents(arrows) {
    const siema = this

    if (!arrows) return

    if (arrows.next) {
      arrows.next.addEventListener('click', (event) => {
        event.preventDefault()
        siema.next(siema._checkHowMany())
      })
    }

    if (arrows.prev) {
      arrows.prev.addEventListener('click', (event) => {
        event.preventDefault()
        siema.prev(siema._checkHowMany())
      })
    }
  }

  /**
   * Check window.innerWidth to determine how many slides to scroll
   */
  _checkHowMany() {
    const siema = this

    if (siema.perPage == 1) return

    const windowWidth = window.innerWidth

    if (windowWidth > 1024) return 3

    if (windowWidth > 768) return 2

    return 1
  }

  /**
   * Applies a transparent color to pagination arrows if user is on the first or last slide
   */
  _disablePaginationArrows(instance, paginationContainer) {
    const siema = this

    if (siema.currentSlide + 1 == siema.innerElements.length) {
      paginationContainer.querySelector('[data-next]').style.opacity = '0.3'
    } else {
      paginationContainer.querySelector('[data-next]').style.opacity = '1'
    }

    if (siema.currentSlide == 0) {
      paginationContainer.querySelector('[data-prev]').style.opacity = '0.3'
    } else {
      paginationContainer.querySelector('[data-prev]').style.opacity = '1'
    }
  }

  /**
   * Sets a 'data-slide-state' attribute on the first element INSIDE your data-slide
   * States: [data-slide-state="active"], [data-slide-state="outer"], [data-slide-state="hidden"]
   * Currently assumes your outer slides are a single slide before and after your current slides
   * Styles for quickest use:
   * [data-slide-state="active"] { opacity: 1; transition: all 500ms ease;}
   * [data-slide-state="outer"] { opacity: 0.25; transition: all 500ms ease;}
   * [data-slide-state="hidden"] { opacity: 0; visibilty: hidden; transition: all 500ms ease;}
   */
  _assignSlideStates() {
    const siema = this

    const slides = siema.innerElements
    let activeElementIndexes = arrayOfNumbers(
      siema.currentSlide,
      siema.currentSlide - 1 + siema.perPage
    )
    let outerElementIndexes = arrayOfNumbers(
      activeElementIndexes[0] - 1,
      activeElementIndexes[activeElementIndexes.length - 1] + 1
    )

    slides.forEach((slide) => {
      if (activeElementIndexes.indexOf(slides.indexOf(slide)) > -1) {
        slide.setAttribute('data-slide-state', 'active')
      } else if (outerElementIndexes.indexOf(slides.indexOf(slide)) > -1) {
        slide.setAttribute('data-slide-state', 'outer')
      } else {
        slide.setAttribute('data-slide-state', 'hidden')
      }
    })
  }

  /**
   * Applies AOS Library data attributes to the initial slides in view
   */
  _animateSlides() {
    const siema = this

    if (siema.perPage < 2) return

    const allowed = arrayOfNumbers(0, siema.perPage)
    const slides = siema.innerElements
    slides.forEach((slide) => {
      if (allowed.indexOf(slides.indexOf(slide)) > -1) {
        slide.parentNode.setAttribute('data-aos', 'fade-up')
        slide.parentNode.setAttribute('data-aos-duration', '1300')
        slide.parentNode.setAttribute(
          'data-aos-delay',
          `${slides.indexOf(slide) * 100}`
        )
      }
    })
  }
}
