
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import DownloadIcon from '~/static/images/sprites/download.svg'

@Component({
  components: {
    DownloadIcon,
  },
})
export default class Resources extends Vue {
  @Prop({ type: Object }) block?: any
}
