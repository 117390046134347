/**
 * Spreads two numbers into an array of numbers between them.
 * @function
 * @param {Number} start - First number
 * @param {Number} end - Last number
 * @returns {Array} - Array of numbers
 * @example
 * // Choose your start and end numbers
 * let allowedIndexes = arrayOfNumbers(1, 10);
 * elements.forEach((element, index)) => { allowedIndexes.includes(index) ? console.log(true) : console.log(false) })
 */
export const arrayOfNumbers = (start, end) => {
  let arr = []
  for (let i = start; i <= end; i++) {
    arr.push(i)
  }
  return arr
}
