
import { Vue, Component, Prop } from 'vue-property-decorator'

export type Post = {
  content: String
  date: Date
  id: Number
  image: String
  parent: Number
  slug: String
  status: String
  title: String
  type: String
  url: String
}

export type Date = {
  modified: String
  published: String
}

@Component({
  name: 'StoryCard'
})
export default class NewsCard extends Vue {
  @Prop(Object) data!: Post

  dateFormatted(date) {
    const dateFormatted = this.$moment(date, 'YYYY-MM-DD', ' HH-MM-SS').format(
      'MMM DD YYYY'
    )
    return dateFormatted
  }

  get url(): string {
    if (process.server) {
      const URL = require('url').URL
    } else {
      const URL = window.URL
    }

    const url = new URL(this.data.url)
    return url.pathname
  }
}
