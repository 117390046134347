/* Dependencies */
import anime from 'animejs'

/**
 * Helper used to handle the animation between featured images.
 * @function
 * @ignore
 * @param {Object} siema - The carousel instance.
 * @param {Object} data - The slide data.
 * @example
 * // Get the target slide
 * const target = siema.innerElements[slideIndex];
 *
 * // Get the current slide data
 * const currentSlideData = {
 *     image: target.getAttribute("data-slide-image"),
 *     caption: target.getAttribute("data-slide-caption")
 * };
 *
 * // Update slider position
 * siema.goTo(slideIndex);
 *
 * // Load the new item
 * loadNewFeatured(siema, currentSlideData);
 */
export const loadNewFeatured = (siema, data) => {
  // If a timeline is playing
  if (siema._featured.timeline) {
    // Pause it (Prevents flashing on fast clicks)
    siema._featured.timeline.pause()
  }

  // Create a new timeline
  siema._featured.timeline = anime
    .timeline({
      repeat: false,
      autoPlay: false
    })
    // Add scene
    .add({
      targets: siema._featured.container,
      opacity: 0,
      duration: 200,
      easing: 'linear',
      complete: () => {
        // Update to new content
        siema._featured.imageContainer.style.backgroundImage = `url(${data.image})`
        siema._featured.caption.innerHTML = data.caption
      }
    })
    // Add scene
    .add({
      targets: siema._featured.container,
      opacity: 1,
      delay: 100,
      duration: 200,
      easing: 'linear'
    })

  // Play
  siema._featured.timeline.play()
}

/**
 * Create pagination item.
 * @function
 * @param {Number} index - The slide index.
 * @param {Object} carousel - The carousel instance.
 * @returns {HTMLElement} - The html element for the pagination item.
 * @example
 * const paginationItems = siema.innerElements.map((currentItem, index) => {
 *     createPaginationItem(index, siema);
 * });
 */
export const createPaginationItem = (index, carousel) => {
  // Create a button element
  const templateBtn = document.createElement('button')
  templateBtn.setAttribute('data-pagination-item', '')
  templateBtn.setAttribute('aria-label', `Pagination - Go To Slide ${index}`)

  // Add a click event
  templateBtn.addEventListener('click', event => {
    // Prevent default
    event.preventDefault()
    // Go to the index
    carousel.goTo(index)
  })

  // Return the element
  return templateBtn
}

export const createArrow = ({ ariaText, icon, direction }) => {
  const button = document.createElement('button')
  button.setAttribute('data-arrow', '')
  button.setAttribute('aria-label', ariaText)
  button.insertAdjacentHTML('beforeend', `${icon}`)
  button.setAttribute(`data-${direction}`, '')
  return button
}
