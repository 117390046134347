
import { Vue, Component, Prop } from 'nuxt-property-decorator'
import BannerCta from '~/components/flexible/banner/BannerCta.vue'

export type BannerBlock = {
  image_src: object
  title: string
  description: string
  button_text: string
  url: string
  full_height?: boolean
}

@Component({
  components: {
    BannerCta,
  },
})
export default class Banner extends Vue {
  @Prop(Object) block?: BannerBlock

  public bgColor: string = '#00b4cd'

  get imageSrc() {
    if (this.block.image_src) {
      if (this.$mq === 'sm' && this.block.image_src.sizes.large) {
        return this.block.image_src.sizes.large
      }
      //return this.block.image_src.sizes['1536x1536']
      return this.block.image_src.sizes['1536x1536']
    } else {
      return this.block.image_src
    }
  }
}
