
import { Component, Vue, Prop } from 'vue-property-decorator'
import Loader from '~/components/ui/Loader.vue'
import EventCard from '~/components/cards/EventCard.vue'
import ProductCard from '~/components/cards/ProductCard.vue'
import NewsCard from '~/components/cards/NewsCard.vue'
import PageCard from '~/components/cards/PageCard.vue'
import { initCarousel } from '~/modules/carousel/initCarousel'

@Component({
  components: {
    Loader,
    EventCard,
    ProductCard,
    NewsCard,
    PageCard
  }
})
export default class CardCarousel extends Vue {
  @Prop({ type: Object }) block?: any

  public loading: boolean = false

  get cardComponentName(): string {
    return ` ${this.block.type} Card`
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
  }

  initCarousel(): void {
    Vue.nextTick(() => {
      if (this.$refs.carousel) {
        const paginationContainer = this.$refs.carousel.querySelector(
          '[data-pagination]'
        )
        const carouselElement = this.$refs.carousel.querySelector(
          '[data-carousel]'
        )
        initCarousel({
          carouselElement,
          paginationContainer,
          perPage: {
            768: 3,
            1024: 3
          },
          duration: 500,
          onInit() {
            this._addSlideIds(this)
            if (this.innerElements[0]) {
              this.innerElements[0].parentNode.setAttribute(
                'data-slide-state',
                'active'
              )
            }
          },
          onChange() {
            this._updatePagination(this)
            this._disablePaginationArrows(this, paginationContainer)
            this._assignSlideStates(this)
          },
          loop: false
        })
      }
    })
  }

  mounted(): void {
    if (this.$refs.carousel) {
      this.initCarousel()
    }
  }
}
