
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { initCarousel } from '~/modules/carousel/initCarousel'
import Quote from '~/components/flexible/quotes/Quote.vue'

// something to note - Quote vs Review confusing terminology
// 'quote' is a property on a Review in Skyway
@Component({
  components: { Quote }
})
export default class Quotes extends Vue {
  @Prop() block?: any

  initCarousel(): void {
    Vue.nextTick(() => {
      if (this.$refs.carousel) {
        const paginationContainer = this.$refs.carousel.querySelector(
          '[data-pagination]'
        )
        const carouselElement = this.$refs.carousel.querySelector(
          '[data-carousel]'
        )
        initCarousel({
          carouselElement,
          paginationContainer,
          perPage: 1,
          duration: 500,
          onInit() {
            this._addSlideIds(this)
            // this.innerElements[0].parentNode.setAttribute(
            //   'data-slide-state',
            //   'active'
            // )
          },
          onChange() {
            this._updatePagination(this)
            this._disablePaginationArrows(this, paginationContainer)
            //this._assignSlideStates(this)
          },
          loop: false
        })
      }
    })
  }

  mounted(): void {
    this.initCarousel()
  }
}
